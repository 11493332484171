import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type CardProps = {
  readonly children?: ReactNode
  readonly className?: string
}

export const Card = ({ children, className }: CardProps) => {
  return (
    <div
      className={twMerge(
        'rounded-lg border-[2px] border-lighter bg-foreground p-3 shadow-md md:p-4',
        className,
      )}
    >
      {children}
    </div>
  )
}
