import { useQueryClient } from '@tanstack/react-query'

import { Button } from '../../components/input/Button/Button'
import { Card } from '../../components/layout/Card/Card'
import { SizeContainer } from '../../components/layout/SizeContainer/SizeContainer'
import { capitalize } from '../../helpers/capitalize'
import { useTranslation } from '../../i18n/useTranslation'

type ErrorPageProps = {
  error: Error & { digest?: string }
  reset: () => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const propToString = (error: any, prop: string) => {
  const val = error[prop]

  if (val.toString) return val.toString()

  return JSON.stringify(val)
}

const ErrorCardContent = ({ error }: { error: Error & { digest?: string } }) => {
  return (
    <>
      <div className='flex flex-col gap-4'>
        {error &&
          Object.getOwnPropertyNames(error).map(prop => (
            <div key={prop}>
              <p className='font-bold'>{capitalize(prop)}</p>
              <span className='whitespace-pre-wrap'>{propToString(error, prop)}</span>
            </div>
          ))}
      </div>
    </>
  )
}

export const ErrorPage = ({ error, reset }: ErrorPageProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const failedQueries = queryClient.getQueriesData({
    predicate: query => query.state.status === 'error',
  })

  return (
    <SizeContainer size='md'>
      <div className='mt-[10vw] flex w-full flex-col items-center justify-center gap-4'>
        <p className='text-center text-lg'>{t.general.errors.error}</p>
        {process.env.NODE_ENV === 'development' && (
          <Card className='w-full overflow-y-auto'>
            <ErrorCardContent error={error} />

            {failedQueries.length > 0 && (
              <>
                <p className='mt-4 font-bold'>Failed Queries</p>
                <ul className='ml-4 list-inside list-disc'>
                  {failedQueries.map(([queryKey]) => (
                    <li key={queryKey[0] as string}>{queryKey[0] as string}</li>
                  ))}
                </ul>
              </>
            )}
          </Card>
        )}
        <Button
          className='w-max'
          onClick={() => {
            reset()
            queryClient.refetchQueries({ predicate: query => query.state.status === 'error' })
          }}
        >
          {t.general.errors.retry}
        </Button>
      </div>
    </SizeContainer>
  )
}
